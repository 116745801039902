import { appConfig } from '@vectroid/config'
import { useAuthStore } from '@vectroid/shared/auth'
import { Divider } from 'components/catalyst/divider'
import { Field } from 'components/catalyst/fieldset'
import { Subheading } from 'components/catalyst/heading'
import { Input } from 'components/catalyst/input'
import { Text } from 'components/catalyst/text'
import { CopyButton } from 'components/copy-button'
import { useContext } from 'react'
import { IndexDetailContext } from '../../context'

function AccessDatabasePage() {
  const { datasetDetail } = useContext(IndexDetailContext)
  const { apiKey } = useAuthStore()

  const apiBaseUrl = appConfig.get('API_BASE_URL')!
  const dataCellBaseUrl = `https://${datasetDetail.uri}`

  return (
    <>
      <section>
        <div className="col-span-3 mt-6 max-w-4xl">
          <section className="flex items-start space-x-4">
            <div className="mt-2 w-[200px]">
              <Subheading>API Base URL</Subheading>
            </div>
            <Field className="flex-1">
              <Input readOnly defaultValue={apiBaseUrl} />
              <Text className="mt-2 !text-xs">A RESTful endpoint for managing your dataset and index & dataset.</Text>
            </Field>

            <CopyButton text={apiBaseUrl} />
          </section>

          <Divider soft className="my-6" />

          <section className="flex items-start space-x-4">
            <div className="mt-2 w-[200px]">
              <Subheading>Data Cell Base URL</Subheading>
            </div>
            <Field className="flex-1">
              <Input readOnly defaultValue={dataCellBaseUrl} />
              <Text className="mt-2 !text-xs">A RESTful endpoint for upserting and querying your index & dataset.</Text>
            </Field>

            <CopyButton text={dataCellBaseUrl} />
          </section>

          <Divider soft className="my-6" />

          <section className="flex items-start space-x-4">
            <div className="mt-2 w-[200px]">
              <Subheading>API Key</Subheading>
            </div>
            <Field className="flex-1">
              <Input type="password" readOnly defaultValue={apiKey as string} />
              <Text className="mt-2 !text-xs">
                A unique identifier for authenticating your requests. Keep this key secure.
              </Text>
            </Field>
            <CopyButton text={apiKey as string} />
          </section>
        </div>
      </section>
    </>
  )
}

export default AccessDatabasePage
