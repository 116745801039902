import { z } from 'zod'
import { CreateIndexFormSchema } from '../schema'

import { useNotificationStore } from 'components/common'
import { Form } from 'components/common/form/form'
import { Field, FieldTypes, GroupConfig } from 'components/common/form/types'
import { RouteNames } from '@vectroid/shared/const'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getSimilarityFunctionOptions, SimilarityFunctions } from '@vectroid/shared/const'
import { createIndexApi } from '../services'
import { useIndexStore } from '../store'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

const formSchema = CreateIndexFormSchema

type FormSchema = z.input<typeof formSchema>
type FormOutput = z.output<typeof formSchema>

enum FieldNames {
  datasetName = 'datasetName',
  indexName = 'indexName',
  similarity = 'similarity',
  hnswConnections = 'hnswConnections',
  hnswBeamWidth = 'hnswBeamWidth',
  quantizationType = 'quantizationType',
  quantizationBits = 'quantizationBits',
}

enum FieldGroups {
  config = 'config',
}

const baseFormFields: Field[] = [
  {
    name: FieldNames.datasetName,
    label: 'Select Dataset',
    fieldType: FieldTypes.DatasetSelect,
  },
  {
    name: FieldNames.indexName,
    label: 'Index Name',
    slugifyValue: true,
    renderHint: (value: string) => (value ? `Your index will be created as "<b>${value}</b>"` : undefined),
  },
  {
    name: FieldNames.similarity,
    fieldType: FieldTypes.Select,
    options: getSimilarityFunctionOptions(),
    label: 'Similarity Function',
  },
  {
    name: FieldNames.hnswConnections,
    label: 'm',
    type: 'number',
    groupId: FieldGroups.config,
  },
  {
    name: FieldNames.hnswBeamWidth,
    label: 'ef_construction',
    type: 'number',
    groupId: FieldGroups.config,
  },
  {
    name: FieldNames.quantizationType,
    label: 'Quantization Type',
    fieldType: FieldTypes.Select,
    type: 'number',
    options: [
      { label: 'No quantization', value: 'none' },
      { label: 'Scalar quantization', value: 'scalar' },
    ],
    groupId: FieldGroups.config,
  },
]

const quantizationBitsField: Field = {
  name: FieldNames.quantizationBits,
  label: 'Quantization Compression',
  fieldType: FieldTypes.Select,
  type: 'number',
  options: [
    { label: '4x compression', value: 7 },
    { label: '8x compression', value: 4 },
  ],
  groupId: FieldGroups.config,
}

const defaultValues = {
  [FieldNames.datasetName]: '',
  [FieldNames.indexName]: '',
  [FieldNames.similarity]: SimilarityFunctions.Cosine,
  [FieldNames.hnswConnections]: 32,
  [FieldNames.hnswBeamWidth]: 200,
  [FieldNames.quantizationType]: 'none',
  [FieldNames.quantizationBits]: 32,
}

export function CreateIndexForm({ onCancel }: { onCancel?: () => void }) {
  const [loading, setLoading] = useState(false)
  const { setMessage } = useNotificationStore()
  const { fetchIndexes } = useIndexStore()
  const navigate = useNavigate()

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  const quantizationType = form.watch(FieldNames.quantizationType)

  const formFields = quantizationType === 'scalar' ? [...baseFormFields, quantizationBitsField] : baseFormFields

  const groupConfigs: GroupConfig[] = [
    {
      title: 'HNSW Parameters',
      id: FieldGroups.config,
      collapsed: false,
    },
  ]

  async function onSubmit(values: FormSchema) {
    const transformedValues = formSchema.parse(values) as FormOutput

    try {
      setLoading(true)
      setMessage(`<b>${values.indexName}</b> is creating now`, { type: 'loading' })

      const quantizationBits = values.quantizationType === 'none' ? 32 : values.quantizationBits

      const response = await createIndexApi({
        datasetName: transformedValues.datasetName,
        indexName: transformedValues.indexName,
        similarity: transformedValues.similarity,
        config: {
          id: 1,
          name: transformedValues.indexName,
          hnswConfig: {
            hnswConnections: transformedValues.hnswConnections,
            hnswBeamWidth: transformedValues.hnswBeamWidth,
            quantizationBits,
          },
        },
      })

      if (response?.data?.indexId > 0) {
        setMessage(`<b>${values.indexName}</b> has been created. You are being redirected..`, { type: 'success' })
        onCancel?.()
        await fetchIndexes()

        navigate(RouteNames.IndexDetailConsole.get(`${values.indexName}`, 'insert'))
      }
    } catch (e: any) {
      setMessage(e.message, { type: 'error' })
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form
      onCancel={onCancel}
      onSubmit={onSubmit}
      formSchema={formSchema}
      groupConfigs={groupConfigs}
      defaultValues={defaultValues}
      form={form}
      formFields={formFields}
      loading={loading}
      actionBtnText="Create Index"
    />
  )
}
