import { Badge } from 'components/catalyst/badge'
import { Button } from 'components/catalyst/button'
import { Card } from 'components/catalyst/card'
import { Divider } from 'components/catalyst/divider'
import { CodeEditor } from 'components/code-editor'
import { CommonTooltip } from 'components/common/tooltip/tooltip'
import { Play, Sparkles } from 'lucide-react'
import { useEffect, useState } from 'react'
import { insertDataApi } from '../services'
import { useDatasetDetailStore } from '../store/datase-detail.store'
import { generateMockVectors } from '../utils'
import { getDatasetURL } from '../utils/dataset-uri'

export function DatasetInsertEditor() {
  const dataset = useDatasetDetailStore((state) => state.dataset)

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [code, setCode] = useState('')

  function onChange(value: string) {
    setCode(value)
  }

  function addRandomVectors() {
    const newVectors = generateMockVectors(5, dataset?.dimensions!)
    const lines = newVectors.split('\n')
    let currentCode = ''

    async function addLines() {
      for (let i = 0; i < lines.length; i++) {
        currentCode += lines[i] + '\n'
        setCode(currentCode) // Update the code
        await new Promise((resolve) => setTimeout(resolve, 10)) // Delay for 10ms
      }
    }

    addLines()
  }

  async function onInsert() {
    try {
      setLoading(true)
      const parsed = JSON.parse(code)
      await Promise.all(
        parsed.map((item: any) =>
          insertDataApi(getDatasetURL(dataset?.uri!), {
            datasetName: dataset?.name!,
            data: item,
          })
        )
      )

      addRandomVectors()
      setSuccess(true)
      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setCode(generateMockVectors(5, dataset?.dimensions!))
  }, [dataset])

  return (
    <Card
      heading="Insert Vectors"
      contentClasses="flex flex-col h-[300px]"
      actions={
        <CommonTooltip content="Generate random vectors">
          <Button plain onClick={addRandomVectors}>
            <Sparkles size={16} strokeWidth={2.5} />
          </Button>
        </CommonTooltip>
      }
    >
      <div className="h-[250px] flex-1 overflow-y-scroll">
        <CodeEditor code={code} onChange={onChange} containerComponent={'div'} />
      </div>
      <Divider />
      <div className="mt-4 flex justify-end space-x-4">
        {success && (
          <Badge className="px-2" color="green">
            Success!
          </Badge>
        )}
        <CommonTooltip enabled={!dataset?.uri} content="Dataset is not ready to insert data yet..">
          <Button disabled={!dataset?.uri} loading={loading} className="inline-flex" onClick={onInsert}>
            <Play size={16} strokeWidth={2.5} /> Insert
          </Button>
        </CommonTooltip>
      </div>
    </Card>
  )
}
