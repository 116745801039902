import { appConfig } from '@vectroid/config'

const GoogleSSOCallback = '/oauth2/callback'

const redirectUri = typeof window !== 'undefined' ? `${window.location.origin}/console${GoogleSSOCallback}` : ''
const authEndpoint = 'https://accounts.google.com/o/oauth2/v2/auth'
const scope = 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'

function generateCryptoRandomState() {
  const randomValues = new Uint32Array(2)
  window.crypto.getRandomValues(randomValues)

  const utf8Encoder = new TextEncoder()
  // @ts-ignore
  const utf8Array = utf8Encoder.encode(String.fromCharCode.apply(null, randomValues))

  // @ts-ignore
  return btoa(String.fromCharCode.apply(null, utf8Array)).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
}

export function parseQueryParams() {
  const fragmentString = window.location.hash.substring(1)
  const params = {}
  const regex = /([^&=]+)=([^&]*)/g
  let m
  while ((m = regex.exec(fragmentString))) {
    // @ts-ignore
    params[decodeURIComponent(m[1])] = decodeURIComponent(m[2])
  }
  // @ts-ignore
  if (Object.keys(params).length > 0 && params['state']) {
    // @ts-ignore
    if (params['state'] === window.localStorage.getItem('state')) {
      return params
    } else {
      console.log('State mismatch. Possible CSRF attack')
    }
  }
}

export function handleGoogleSSOLogin() {
  const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth'
  const state = generateCryptoRandomState()
  localStorage.setItem('state', state)

  const form = document.createElement('form')
  form.setAttribute('method', 'GET')
  form.setAttribute('action', oauth2Endpoint)

  const clientId = appConfig.get('GOOGLE_OAUTH_CLIENT_ID')

  const params = {
    client_id: clientId,
    redirect_uri: redirectUri,
    response_type: 'token',
    scope,
    include_granted_scopes: 'true',
    state,
  }

  for (const p in params) {
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', p)
    // @ts-ignore
    input.setAttribute('value', params[p])
    form.appendChild(input)
  }

  document.body.appendChild(form)
  form.submit()
}
