import { FacKeys, isFeatureEnabled } from '@vectroid/config'
import { BetaFeature } from 'components/beta-feature'
import { Button } from 'components/catalyst/button'
import { Dialog, DialogBody, DialogTitle } from 'components/catalyst/dialog'
import { CommonTooltip } from 'components/common/tooltip/tooltip'
import { HardDriveUpload } from 'lucide-react'
import { useState } from 'react'
import { UploadStatus } from '../const/upload-status.enum'
import { useDatasetBulkUploadStore } from '../store/dataset-bulk-upload.store'
import { DatasetBulkUpload } from './dataset-bulk-upload'

export function DatasetBulkUploadDialog() {
  const [isOpen, setIsOpen] = useState(false)
  const uploadStatus = useDatasetBulkUploadStore((state) => state.uploadStatus)

  const isUploading = uploadStatus === UploadStatus.Uploading

  return (
    <>
      <BetaFeature enabled={isFeatureEnabled(FacKeys.BulkUpload)}>
        <CommonTooltip enabled={isUploading} content="Please wait until the current upload is completed.">
          <Button onClick={() => setIsOpen(true)} color="light" disabled={isUploading}>
            <HardDriveUpload strokeWidth={2.5} size={16} />
            Bulk Upload
          </Button>
        </CommonTooltip>
      </BetaFeature>

      <Dialog open={isOpen} onClose={setIsOpen} className="!max-w-2xl">
        <DialogTitle>Upload your dataset</DialogTitle>
        <DialogBody>
          <DatasetBulkUpload closeDialog={() => setIsOpen(false)} />
        </DialogBody>
      </Dialog>
    </>
  )
}
