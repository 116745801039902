import { FacKeys } from '../const/fac-keys'

interface FeatureAccessControl {
  name: string
  description: string
  enabled: boolean
}

const controlMap = new Map<FacKeys, boolean>()

export function createFeatureAccessControl(controlFile: FeatureAccessControl[]) {
  controlFile.forEach((control) => {
    controlMap.set(control.name as FacKeys, control.enabled)
  })
}

export function isFeatureEnabled(key: FacKeys): boolean {
  if (controlMap.size === 0) {
    throw new Error('Feature Access Control has not been initialized')
  }

  return controlMap.get(key) || false
}

export function enableFeature(key: FacKeys) {
  controlMap.set(key, true)
}

export function disableFeature(key: FacKeys) {
  controlMap.set(key, false)
}

export function enableAllFeatures() {
  controlMap.forEach((_, key) => controlMap.set(key, true))
}

export function disableAllFeatures() {
  controlMap.forEach((_, key) => controlMap.set(key, false))
}
