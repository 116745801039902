import { cn } from '@vectroid/shared/utils'
import { Badge } from 'components/catalyst/badge'
import { Text } from 'components/catalyst/text'
import { CommonTooltip } from 'components/common/tooltip/tooltip'
import { CircleCheck } from 'lucide-react'
import { getDatasetURL } from 'modules/datasets/utils/dataset-uri'
import { useEffect, useState } from 'react'
import { getIndexSizeApi } from '../services'
import { IndexSizeResponse } from '../types'

type Props = {
  indexName: string
  datasetUri: string
}

export function IndexSizeProgress(props: Props) {
  const [indexSize, setIndexSize] = useState<IndexSizeResponse | null>(null)
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null)

  const indexingPercentage =
    indexSize?.indexOperationCount && indexSize?.datasetOperationCount
      ? (indexSize.indexOperationCount / indexSize.datasetOperationCount) * 100
      : 0

  useEffect(() => {
    let isMounted = true

    async function fetchIndexSize() {
      try {
        const response = await getIndexSizeApi(getDatasetURL(props.datasetUri), props.indexName)
        if (isMounted) {
          setIndexSize(response.data)
          setLastUpdated(new Date())
        }
      } catch (error) {
        console.error('Error fetching index size:', error)
      }
    }

    // Initial fetch
    fetchIndexSize()

    // Set up polling every 5 seconds
    const interval = setInterval(fetchIndexSize, 5000)

    // Cleanup on component unmount
    return () => {
      isMounted = false
      clearInterval(interval)
    }
  }, [])

  const isCompleted = indexingPercentage >= 100

  return (
    <CommonTooltip
      content={
        <div className="space-y-2 py-2">
          <p>
            Last update: <b>{lastUpdated ? lastUpdated.toLocaleTimeString() : 'N/A'}</b>
          </p>
        </div>
      }
    >
      <div
        className={cn('flex items-center space-x-3 rounded-lg border-zinc-200 transition-all', {
          '-mx-3 -mt-2 px-3 py-1 hover:bg-zinc-100 hover:dark:bg-zinc-900': !isCompleted,
        })}
      >
        <div className="flex items-center justify-center space-x-3">
          {isCompleted ? (
            <Badge color="green">
              <CircleCheck size={18} /> <span className="text-sm font-medium">Up to date</span>
            </Badge>
          ) : (
            <div className="flex items-center space-x-2">
              <div className="flex h-2 w-[140px] items-center space-x-2 rounded-full bg-zinc-200 dark:bg-zinc-800">
                <span
                  className="flex h-2 w-[140px] animate-pulse items-center space-x-2 rounded-full bg-green-700 transition-all dark:bg-green-600"
                  style={{
                    width: indexSize ? `${indexingPercentage}%` : '0%',
                  }}
                />
              </div>
              <div className="min-w-4">
                <Text className="ml-2">{parseInt(`${indexingPercentage}`)}%</Text>
              </div>
            </div>
          )}
        </div>
      </div>
    </CommonTooltip>
  )
}
