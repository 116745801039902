import { useAsyncData } from 'hooks/use-async-data'
import { Index } from 'modules/indexes'
import { useCallback } from 'react'
import { create } from 'zustand'
import { describeDatasetApi, listDatasetIndexesApi } from '../services'
import { Dataset } from '../types'

interface DatasetDetailState {
  dataset: Dataset | null
  datasetSize: number | null
  datasetIndexes: Index[] | null
  setDataset: (dataset: Dataset | null) => void
  setDatasetIndexes: (indexes: Index[] | null) => void
  setDatasetSize: (size: number | null) => void
}

export const useDatasetDetailStore = create<DatasetDetailState>((set) => ({
  dataset: null,
  datasetIndexes: null,
  datasetSize: null,
  setDataset: (dataset) => set({ dataset }),
  setDatasetIndexes: (indexes) => set({ datasetIndexes: indexes }),
  setDatasetSize: (size) => set({ datasetSize: size }),
}))

export function useDatasetDetail(datasetName: string) {
  const { dataset, setDatasetSize, datasetSize, datasetIndexes, setDataset, setDatasetIndexes } =
    useDatasetDetailStore()

  const { loading: datasetLoading, fetchData: describeDataset } = useAsyncData(describeDatasetApi, {
    onDataFetched: (fetchedDatasets) => {
      setDataset(fetchedDatasets?.dataset)
      setDatasetSize(fetchedDatasets?.size)
    },
    params: [datasetName],
    showErrorMessage: true,
  })
  const { loading: datasetIndexesLoading, fetchData: fetchDatasetIndexes } = useAsyncData(listDatasetIndexesApi, {
    onDataFetched: (fetchedIndexes) => setDatasetIndexes(fetchedIndexes?.indexes),
    params: [datasetName],
    showErrorMessage: true,
  })

  const fetchDatasetDetailPage = useCallback(() => {
    describeDataset()
    fetchDatasetIndexes()
  }, [])

  return {
    dataset,
    datasetSize,
    datasetLoading,
    describeDataset,
    datasetIndexesLoading,
    datasetIndexes,
    fetchDatasetIndexes,
    fetchDatasetDetailPage,
  }
}
