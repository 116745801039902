import { Badge } from 'components/catalyst/badge'
import { Button } from 'components/catalyst/button'
import { Play } from 'lucide-react'
import { useCollectionStore } from 'modules/indexes'
import { useEffect, useRef, useState } from 'react'
import { getCollectionColor } from '../utils/playground-helper'

type Props = {
  urlBarDisabled?: boolean
  executeCode: (httpUrl: string) => void
  loading: boolean
  baseUrl: string
}

export function ConsoleHeader(props: Props) {
  const { activeCollection } = useCollectionStore()

  const [httpUrl, setHttpUrl] = useState(activeCollection.endpoint)
  const inputRef = useRef<HTMLInputElement>(null)

  const onExecute = () => {
    props.executeCode(httpUrl)
  }

  useEffect(() => {
    setHttpUrl(activeCollection.endpoint)
    if (/\{[^}]+\}/.test(activeCollection.endpoint)) {
      inputRef.current?.focus()
    }
  }, [activeCollection])

  return (
    <>
      <div className="mb-4 flex items-center rounded-lg border bg-white dark:bg-zinc-950">
        <Badge
          className="!w-16 justify-center !rounded-r-none border-r !px-3 !py-2 font-medium"
          color={getCollectionColor(activeCollection.method)}
        >
          {activeCollection.method}
        </Badge>

        <span className="mr-0.5 pl-2 font-mono text-xs">{props.baseUrl}</span>

        <input
          ref={inputRef}
          value={httpUrl}
          onChange={(e) => setHttpUrl(e.target.value)}
          className="flex h-[24px] w-auto items-center rounded-md bg-zinc-300/30 px-1 font-mono !text-xs text-zinc-700 !outline-none dark:bg-zinc-900/30 dark:text-white"
          style={{ width: `${httpUrl.length + 2}ch` }}
          disabled={props.urlBarDisabled}
        />

        <span className="flex-1" />

        <Button color="amber" className="!rounded-l-none !px-4" onClick={onExecute} loading={props.loading}>
          <Play size={16} strokeWidth={2.5} />
          Run
        </Button>
      </div>
    </>
  )
}
