import { cn, formatVectorCountPrecise } from '@vectroid/shared/utils'
import { Badge } from 'components/catalyst/badge'
import { Card } from 'components/catalyst/card'
import { CloudProviderIcon } from 'components/cloud-provider-icon'
import { CopyButton } from 'components/copy-button'
import { useDatasetDetailStore } from '../store/datase-detail.store'
import { getDatasetStatusBadgeColor } from '../utils'

export function DatasetOverview() {
  const dataset = useDatasetDetailStore((state) => state.dataset)
  const datasetSize = useDatasetDetailStore((state) => state.datasetSize)

  const items = [
    {
      name: 'URI',
      value: (
        <div className="flex items-center space-x-3">
          <code className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap">{dataset?.uri ?? '-'}</code>
          {dataset?.uri && <CopyButton iconOnly text={dataset?.uri} />}
        </div>
      ),
      className: 'col-span-12',
    },
    { name: 'ID', value: dataset?.id ?? '-' },
    {
      name: 'Status',
      value: <Badge color={getDatasetStatusBadgeColor(dataset?.status)}>{dataset?.status}</Badge>,
    },
    {
      name: 'Dimension',
      value: dataset?.dimensions,
    },
    { name: 'Region', value: dataset?.region?.toLowerCase().replace(/_/g, '-') },
    { name: 'Count of Vectors', value: datasetSize ? formatVectorCountPrecise(datasetSize) : '-' },
    {
      name: 'Cloud Provider',
      value: <CloudProviderIcon provider={dataset?.provider!} />,
    },
  ]

  return (
    <Card className="mx-auto p-0">
      <div className="-mb-px grid grid-cols-12">
        {items.map((item) => (
          <div
            key={item.name}
            className={cn(
              'col-span-6 flex-1 border-r border-zinc-200 p-6 dark:border-zinc-800',
              items.indexOf(item) >= items.length - 2 ? '' : 'border-b',
              item.className
            )}
          >
            <dt className="whitespace-nowrap text-[12px] font-semibold uppercase text-zinc-500">{item.name}</dt>
            <dd className="mt-1 text-sm font-medium tracking-tight">{item.value}</dd>
          </div>
        ))}
      </div>
    </Card>
  )
}
