import { AppConfig } from '@vectroid/config'
import { RouteNames } from '@vectroid/shared/const'
import { FileSearch, FileText as DocumentTextIcon, FolderOpenDot, Home, Key, Terminal } from 'lucide-react'

export const getSidebarMenuItems = (appConfig: Map<keyof AppConfig, string>) => [
  [
    { text: 'Dashboard', href: RouteNames.Dashboard, icon: <Home size={18} /> },
    { text: 'Datasets', href: RouteNames.Datasets, icon: <FolderOpenDot size={18} /> },
    { text: 'Indexes', href: RouteNames.Indexes, icon: <FileSearch size={18} /> },
    { text: 'API Keys', href: RouteNames.ApiKeys, icon: <Key size={18} /> },
    { text: 'API Playground', href: RouteNames.ApiPlayground, icon: <Terminal size={18} /> },
  ],
  // { spacer: true },
  { divider: true },
  [
    {
      text: 'API Docs',
      href: appConfig.get('DOCS_BASE_URL'),
      icon: <DocumentTextIcon size={16} />,
      onSelect: () => {
        window.open(appConfig.get('DOCS_BASE_URL'))
      },
    },
  ],
]
