import { apiClient } from '@vectroid/shared/api'
import {
  CreateDatasetRequest,
  CreateDatasetResponse,
  DescribeDatasetResponse,
  InitDatasetBulkUploadSessionRequest,
  InitDatasetBulkUploadSessionResponse,
  InsertDataRequest,
  InsertDataResponse,
  ListDatasetBulkUploadsResponse,
  ListDatasetIndexesResponse,
  ListDatasetResponse,
  StartDatasetBulkIngestionResponse,
} from '../types'

/**
 * Create a dataset
 *
 * @param {CreateDatasetRequest} values - The dataset values
 * @returns {Promise<CreateDatasetResponse>} - The created dataset
 */
export function createDatasetApi(values: CreateDatasetRequest) {
  return apiClient<CreateDatasetRequest, CreateDatasetResponse>('/dataset/create', 'POST', values)
}

/**
 * List datasets
 *
 * @returns {Promise<ListDatasetResponse>} - The list of datasets
 */
export function listDatasetsApi() {
  return apiClient<{}, ListDatasetResponse>('/dataset', 'GET')
}

/**
 * Describe a dataset
 *
 * @param datasetName {String}
 * @returns {Promise<DescribeDatasetResponse>}
 */
export function describeDatasetApi(datasetName: string) {
  return apiClient<{}, DescribeDatasetResponse>(`/dataset/${datasetName}`, 'GET')
}

/**
 * List dataset indexes
 *
 * @param datasetName {String}
 * @returns {Promise<ListDatasetIndexesResponse>}
 */
export function listDatasetIndexesApi(datasetName: string) {
  return apiClient<{}, ListDatasetIndexesResponse>(`/dataset/${datasetName}/indexes`, 'GET')
}

/**
 * Create a dataset
 *
 * @param {InsertDataRequest} values - The dataset values
 * @returns {Promise<InsertDataResponse>} - The created dataset
 */
export function insertDataApi(baseUrl: string, values: InsertDataRequest) {
  return apiClient<InsertDataRequest, InsertDataResponse>('/dataset/insert', 'POST', values, { baseUrl })
}

/**
 * Get signed URL to upload data
 *
 * @param baseUrl {String}
 * @param datasetName {String}
 * @param values {GetSignedUrlRequest}
 * @returns {Promise<InitDatasetBulkUploadSessionResponse>}
 */
export function initDatasetBulkUploadSessionApi(
  baseUrl: string,
  datasetName: string,
  values: InitDatasetBulkUploadSessionRequest
) {
  return apiClient<InitDatasetBulkUploadSessionRequest, InitDatasetBulkUploadSessionResponse>(
    `/dataset/${datasetName}/bulk-upload-session`,
    'POST',
    values,
    {
      baseUrl,
    }
  )
}

/**
 * Get signed URL to upload data
 *
 * @param baseUrl {String}
 * @param datasetName {String}
 * @param uploadId {String}
 * @returns {Promise<StartDatasetBulkIngestionResponse>}
 */
export function startDatasetBulkIngestionApi(baseUrl: string, datasetName: string, uploadId: string) {
  return apiClient<undefined, StartDatasetBulkIngestionResponse>(
    `/dataset/${datasetName}/bulk-upload-session/${uploadId}`,
    'POST',
    undefined,
    {
      baseUrl,
    }
  )
}

/**
 * List dataset bulk uploads
 *
 * @param baseUrl {String}
 * @param datasetName {String}
 * @returns {Promise<ListDatasetBulkUploadsResponse>}
 */
export function listDatasetBulkUploads(baseUrl: string, datasetName: string) {
  return apiClient<undefined, ListDatasetBulkUploadsResponse>(
    `/dataset/${datasetName}/bulk-uploads`,
    'GET',
    undefined,
    {
      baseUrl,
    }
  )
}
