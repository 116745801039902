import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function getInitials(name = '') {
  return name
    .split(' ')
    .map((n) => n?.charAt(0)?.toUpperCase())
    .join('')
}

export const ctrlKey = navigator.platform.includes('Mac') ? '⌘' : 'Ctrl'

export const isLocalhost =
  typeof window !== 'undefined' &&
  Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  )

export function delay(ms = 1000) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function matchDynamicRoute(routePattern: string, pathname: string) {
  if (!routePattern.includes(':')) {
    return routePattern === pathname
  }

  const routeParts = routePattern.split('/')
  const pathParts = pathname.split('/')

  if (routeParts.length !== pathParts.length) {
    return false
  }

  return routeParts.every((part, index) => {
    return part.startsWith(':') || part === pathParts[index]
  })
}

export function formatNumber(num: number) {
  return num.toLocaleString()
}

export function slugify(text: string): string {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .trim()
}

type NestedObject = { [key: string]: any }

export function convertToNestedObject(flatObject: Record<string, any>): NestedObject {
  const nestedObject: NestedObject = {}

  for (const key in flatObject) {
    const value = flatObject[key]
    const keys = key.split('.')

    let currentLevel = nestedObject

    keys.forEach((part, index) => {
      if (index === keys.length - 1) {
        // Last part of the key, assign the value
        currentLevel[part] = value
      } else {
        // Create nested object if it doesn't exist
        if (!currentLevel[part]) {
          currentLevel[part] = {}
        }
        currentLevel = currentLevel[part]
      }
    })
  }

  return nestedObject
}

export function formatVectorCount(count: number): string {
  if (count < 1000) {
    return count.toString()
  } else if (count < 1_000_000) {
    return (count / 1_000).toFixed(1).replace(/\.0$/, '') + 'K'
  } else if (count < 1_000_000_000) {
    return (count / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M'
  } else if (count < 1_000_000_000_000) {
    return (count / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'B'
  } else {
    return (count / 1_000_000_000_000).toFixed(1).replace(/\.0$/, '') + 'T'
  }
}

export function formatVectorCountPrecise(count: number): string {
  const formattedNumber = count.toLocaleString() // Adds commas for better readability

  if (count < 1000) {
    return `${formattedNumber}`
  } else if (count < 1_000_000) {
    return `${(count / 1_000).toFixed(1).replace(/\.0$/, '')}K (${formattedNumber})`
  } else if (count < 1_000_000_000) {
    return `${(count / 1_000_000).toFixed(1).replace(/\.0$/, '')}M (${formattedNumber})`
  } else if (count < 1_000_000_000_000) {
    return `${(count / 1_000_000_000).toFixed(1).replace(/\.0$/, '')}B (${formattedNumber})`
  } else {
    return `${(count / 1_000_000_000_000).toFixed(1).replace(/\.0$/, '')}T (${formattedNumber})`
  }
}

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function formatTimestamp(timestamp: number, options?: Intl.DateTimeFormatOptions): string {
  return new Date(timestamp).toLocaleString(undefined, { timeZoneName: 'short', ...options })
}
